import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';

const ServiceMonitoring = ({ serviceData }) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [customerFilter, setCustomerFilter] = useState('');
    const [serviceFilter, setServiceFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000); // 60 Sekunden

        return () => clearInterval(timer); // Intervall stoppen beim Entfernen des Components
    }, []);

    const uniqueServices = Array.from(new Set(serviceData.map(service => service.SERVICE)));

    const filteredData = serviceData.filter(service => {
        const lastHeartbeat = service.LASTHEARTBEAT ? new Date(service.LASTHEARTBEAT) : null;
        const isRecent = lastHeartbeat && (currentTime - lastHeartbeat) / (1000 * 60) <= 10;
        const customerMatches = service.CUSTOMER_NAME.toLowerCase().includes(customerFilter.toLowerCase());
        const serviceMatches = serviceFilter ? service.SERVICE === serviceFilter : true;
        const statusMatches = statusFilter === ''
            || (statusFilter === 'Aktiv' && isRecent)
            || (statusFilter === 'Inaktiv' && !isRecent);
        return customerMatches && serviceMatches && statusMatches;
    });

    return (
        <div className="container mt-4">
            <h2>Service Monitoring</h2>

            <div className="row mb-3">
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Kunde filtern"
                        value={customerFilter}
                        onChange={(e) => setCustomerFilter(e.target.value)}
                    />
                </div>
                <div className="col-md-4">
                    <select
                        className="form-control"
                        value={serviceFilter}
                        onChange={(e) => setServiceFilter(e.target.value)}
                    >
                        <option value="">Alle Services</option>
                        {uniqueServices.map((service, index) => (
                            <option key={index} value={service}>{service}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4">
                    <select
                        className="form-control"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                    >
                        <option value="">Alle Status</option>
                        <option value="Aktiv">Aktiv</option>
                        <option value="Inaktiv">Inaktiv</option>
                    </select>
                </div>
            </div>

            <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                    <tr>
                        <th>Kunde</th>
                        <th>Service</th>
                        <th>Letzter Heartbeat</th>
                        <th>Version</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((service, index) => {
                        const lastHeartbeat = service.LASTHEARTBEAT ? new Date(service.LASTHEARTBEAT) : null;
                        const isNotConfigured = !lastHeartbeat || lastHeartbeat.toISOString() === "1970-01-01T01:00:00.000Z";
                        const isRecent = lastHeartbeat && (currentTime - lastHeartbeat) / (1000 * 60) <= 10;
                        
                        // Legt die Zeilenklasse basierend auf den Bedingungen fest
                        const rowClass = isNotConfigured
                            ? "table-warning"
                            : isRecent
                            ? "table-success"
                            : "table-danger";
                        
                        return (
                            <tr key={index} className={rowClass}>
                                <td>{service.CUSTOMER_NAME}</td>
                                <td>{service.SERVICE}</td>
                                <td>
                                    {isNotConfigured ? "" : lastHeartbeat.toLocaleString('de-DE')}
                                </td>
                                <td>
                                    {service.VERSION}
                                </td>                                
                                <td>{isNotConfigured ? "nicht eingerichtet" : isRecent ? "Aktiv" : "Inaktiv"}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

ServiceMonitoring.propTypes = {
    serviceData: PropTypes.arrayOf(
        PropTypes.shape({
            CUSTOMERID: PropTypes.string.isRequired,
            SERVICE: PropTypes.string.isRequired,
            LASTHEARTBEAT: PropTypes.string,
        })
    ).isRequired,
};

export default ServiceMonitoring;
